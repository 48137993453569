<template>
  <b-modal
    v-model="dialogState_"
    centered
    id="hectarPlusModal"
    hide-footer
    hide-header
  >
    <b-container>
      <div class="d-flex py-4 text-center">
        <b-img
          style="cursor:pointer"
          @click="closeModal"
          width="30"
          height="30"
          :src="closeHectarPlus"
        ></b-img>

        <div class="w-75">
          <b-img class="mt-3 mr-5 " :src="hectarPlusIcon" width="100"></b-img>
        </div>
      </div>
      <div class="mt-4 mb-5 text-center ">
        <h3 class="mb-0">
          هكتار بلس منصة تقنية مميزة لإدارة
        </h3>

        <h3 class="mt-0">
          اعمال إدارة الأملاك
        </h3>
      </div>

      <div class="my-3 pt-3 d-flex justify-content-center">
        <b-button @click.prevent="openHectarPlus" class="hectar-plus-btn">
          الانتقال لهكتار بلس
        </b-button>
      </div>
    </b-container>
  </b-modal>
</template>

<script>
export default {
  props: {
    dialogState: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      hectarPlusIcon: require('@/assets/images/hectarLogo.png'),
      closeHectarPlus: require('@/assets/images/svgs/messages/close-xs-messages.svg')
    }
  },
  computed: {
    dialogState_: {
      get() {
        return this.dialogState
      },
      set(value) {
        this.$emit('changeDialogState', value)
      }
    }
  },
  methods: {
    openHectarPlus() {
      window.open('https://hectarplus.io/', '_blank')
    },
    closeModal() {
      this.dialogState_ = false
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .modal-backdrop {
  opacity: 0.5 !important;
}
::v-deep .modal-content {
  border: 2px solid #000000 !important;
  border-radius: 20px !important;
  @media (max-width: 576px) {
    width: 80% !important;
    margin: auto !important;
  }
}

.hectar-plus-btn {
  color: #ffffff;
  background-color: #652d90;
  border-radius: 20px;
  width: 80%;
  height: 70px;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
}
</style>
